$window-padding: 25px;

$min-site-width: 320px;
$max-site-width: 940px;
$inner-site-padding: 7%;
$inner-site-padding-min: percentage-of($inner-site-padding, $min-site-width);
$inner-site-padding-max: percentage-of($inner-site-padding, $max-site-width);
$height-of-16-9-screen: 9/16 * 100vw;

$spacing: 30px;
$spacing--mega: 8%;


$sling-ease: cubic-bezier(0.16, 0, 0, 1);

$color-blue: #004C6C;
$color-light-blue: #E7EFF3;
$color-mid-blue: #CBDBE4;
$color-beige: #F6F3EA;

@include breakpoints(site-width-limit, within, $max-site-width, over);

@mixin limited-vw-teti($property, $from-size, $to-size, $unlimited: false) {
	@include limited-vw($property, $from-size, $min-site-width, $to-size, $max-site-width, $unlimited)
}
