$font-size: 20px;
$line-height: 1.4;
$line-height-px: $font-size * $line-height;
$line-margin: $line-height-px * 0.5;

html {
	font: #{$font-size}/#{$line-height} Optima, Corbel, sans-serif;
  @include limited-vw-teti(font-size, 16px, $font-size);
}
.hA {
  font-weight: normal;
  font-size: 40px;
  margin-top: 0;
  margin-bottom: 0em;
  line-height: 1.1;
  @include limited-vw-teti(font-size, 25px, 40px);
}
.hB {
  font-weight: normal;
  margin-bottom: 0;
  .hA + & {
    @extend %content-block;
  }
  @include limited-vw-teti(font-size, 22px, 24px);
}

.u-thick {
	font-weight: bold;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
