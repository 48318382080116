@include breakpoints(contacts, compact, 500px, normal);
@include breakpoints(rates-table, multiline, 500px, normal);

/**
 * BASE
 */

%content-block {
  margin-top: 0;
  margin-bottom: $font-size * $line-height;
  &:last-child {
    margin-bottom: 0;
  }
}

p,
blockquote {
  @extend %content-block;
}

/**
 * LINKS
 */

a {
  color: inherit;
}

.Link--styled {
  text-decoration: none;
  outline: none;
}
.Link--invisible {
  text-decoration: none;
}
// .Link--blendIn {//a link that's not necessary but it can be useful, like in titles
// 	@extend .Link--styled;
// 	&:hover,
// 	&:focus {
// 		color: #ffa500;
// 	}
// }
// .Link--visible {
// 	@extend .Link--styled;
// 	font-weight: bold;
// 	&:hover,
// 	&:focus {
// 		text-decoration: underline;
// 	}
// }

/**
 * SECTION
 */

.Section {
  background: #FFF;
  margin-bottom: $spacing;
  box-shadow: #7757001a 0px 2px 19px;
  blockquote {
    margin-left: 0;
    padding-left: 20px;
    border-left: 3px solid;
  }
  // Float out of the way of Section-cover-th-link
  .hA {
    float: left;
  }
  .hB {
    clear: left;
  }
}

.Section-text {
  padding: $spacing--mega;
  max-width: 620px;
}

.Section-cover {
  img, iframe {
    display: block;
    width: 100%;
  }
  iframe { // Google Maps background
    background: #aadaff;
  }
}
.Section-cover-media {
  overflow: hidden;
}
.Section-cover-nav {
  float: right;
}

.Section-cover-media-wrap {
  overflow: hidden;
  &.js-hidden {
    transition: transform 0.3s;
    transform: translateY(-100%);
    img, iframe {
      transition: transform 0.3s;
      transform: translateY(100%);
    }
  }
}

.Section-cover-th-link {
  @extend %button-reset;
  position: relative;
  overflow: hidden;
  float: left;
  transform: translateY(-50%);
  transform-origin: top;
  margin-bottom: -20px;
  border: 3px solid #fff;
  background: #fff;
  transition: transform 0.3s;
  margin-right: -3px;
  &:last-child {
    margin-right: 0;
    border-right: none;
  }
  img {
    width: auto;
    min-height: 30px;
    max-height: 15vw;
    transition: 0.3s;
    transition-property: transform, opacity;
  }

  &[disabled] {
    transform: scaleY(0.5);
  }
  &[disabled] img {
    transform: translateY(-100%);
  }
}

.Section--detached {
  margin-top: $spacing--mega;
  margin-bottom: $spacing--mega;
}

.Section--mini {
  padding: $spacing/2 $spacing;
  font-size: 0.8em;
}

/**
 * TABLES
 */

.Table-caption {
  text-align: center;
  font-size: 0.9em;
  padding: 0.2em;
}

.Rates {
  width: 100%;
  border-collapse: collapse;
  th {
    font-weight: normal;
    font-style: italic;
    font-size: 0.9em;
    padding: 0.2em;
  }
  td {
    padding: 0.2em;
    text-align: right;
    &:after {
      content: "";
      display: inline-block;
      width: 50%;
      width: calc(50% - 1.3em);
    }
  }
  thead th {
    vertical-align: bottom;
  }
  tbody {
    border-bottom: 1px solid;
    border-top: 1px solid;
    tr {
      transition: background-color 0.2s;
      &:hover {
        transition: none;
        background-color: $color-beige;
      }
    }
  }
}
@include media(rates-table, multiline) {
  .Rates {
    margin-left: -$inner-site-padding-min;
    margin-right: -$inner-site-padding-min;
    width: calc(100% + #{$inner-site-padding-min} * 2);
  }
}

.Rates-time.Rates-time {
  white-space: nowrap;
  @include media(rates-table, multiline) {
    padding: 0.4em 0;
    span {
      display: block;
    }
    .Rates-time-separator {
      display: none;
    }

    span :not(:empty):first-child::before {
      content: 'Dal ';
    }

    span :not(:empty):last-child::before {
      content: 'al ';
    }

    span :empty ~ :last-child::before {
      content: 'Fino al ';
    }

    span :empty:last-child::before {
      content: 'in poi';
    }
  }
  @include media(rates-table, normal) {
    > span {
      display: flex;
      width: 100%;
    }
    span :first-child,
    span :last-child {
      flex-basis: 50%;
    }
    span :first-child {
      text-align: right;
    }
    span :last-child {
      text-align: left;
    }
    .Rates-time-separator {
      padding: 0 0.1em;
    }
  }
}

/**
 * MENU LIST
 */

.MenuList {
  list-style: none;
  padding-left: 0;
  li {
    @extend %content-block;
  }
}

@include media(contacts, compact) {
  .Field-label {
    text-transform: capitalize;
  }
  .no-compact {
    display: none;
  }
}
