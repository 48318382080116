$field-height: 1.8em;
$field-padding: 0.5em;

.Field {
	display: flex;
	position: relative;
	margin-bottom: $field-padding;
	border: 1px solid $color-mid-blue;
	background-color: $color-beige;
	line-height: $field-height;
	transition: 0.2s ease-out;
	transition-property: border-color, background-color;
	&:focus-within {
		border-color: $color-blue;
		background-color: lighten($color-beige, 3%);
	}
}
.Field-label {
	@extend .u-thick;
	// font-size: 0.9em;
	white-space: nowrap;
	padding: 0 0.3em 0 $field-padding;
}

.Field-label--forTextarea {
	position: absolute;
	top: 0;
	left: 0;
	background: inherit;
}
.Field-input {
	@extend %text-field-reset;
	padding: 0;
	height: $field-height;
}
.Field-textarea {
	padding-left: $field-padding;
	padding-right: $field-padding;
	height: auto;
	width: 100%;
}

.Field-textarea--messaggio {
	text-indent: 5.1em; //value depends on label length
}

.Button {
	@extend .u-thick;
	@extend %button-reset;
	display: block;
	margin-left: auto;
	background: $color-blue;
	padding: 0.1em 0.5em;
	color: #FFF;
	transition: background-color 0.3s;
	&:hover,
	&:focus {
		background-color: lighten($color-blue, 10%);
	}
}
