@mixin limited-vw($property, $from-size, $from-screen-size, $to-size, $to-screen-size, $unlimited: false) {
	#{$property}: $from-size;
	@media (min-width: #{$from-screen-size}) {
		#{$property}: constrained-viewport-units($from-size, $from-screen-size, $to-size, $to-screen-size);
	}
	@if not $unlimited {
		@media (min-width: #{$to-screen-size}) {
			#{$property}: $to-size;
		}
	}
}

// https://madebymike.com.au/writing/precise-control-responsive-typography/
@function constrained-viewport-units ($from-size, $from-screen-size, $to-size, $to-screen-size) {
	$screen-size-difference: _constrained-viewport-units_strip-units($to-screen-size - $from-screen-size);
	$size-difference: _constrained-viewport-units_strip-units($to-size - $from-size);
	@return calc( #{$from-size} + #{$size-difference} * ((100vw - #{$from-screen-size}) / #{$screen-size-difference} ));
}

//https://stackoverflow.com/a/12335841/288906
//How do you strip the unit from any number in SASS?
@function _constrained-viewport-units_strip-units($number) {
	@return $number / ($number * 0 + 1);
}



// untested, use as limited-vw(320px 1280px, height 10px 20px, width 30px 40px)
// @mixin limited-vw($boundaries, ...$properties) {
// 	$from-screen-size: nth($boundaries, 1);
// 	$to-screen-size: nth($boundaries, 2);
// 	@each $prop, $from-size, $to-size in $properties {
// 		#{$property}: $from-size;
// 	}
// 	@media (min-width: #{$from-screen-size}) {
// 		@each $prop, $from-size, $to-size in $properties {
// 			#{$property}: constrained-viewport-units($from-size, $from-screen-size, $to-size, $to-screen-size);
// 		}
// 	}
// 	@if not $unlimited {
// 		@media (min-width: #{$to-screen-size}) {
// 			@each $prop, $from-size, $to-size in $properties {
// 				#{$property}: $to-size;
// 			}
// 		}
// 	}
// }
