/**
 * fixed-ratio
 * Make an element keep its ratio when its container is resized
 * 2016-10-31
 * @param  {pixels}     $pixel-width    Pixel-based width of the element
 *                                      (e.g. the width of the video)
 * @param  {pixels}     $pixel-height   Pixel-based height of the element
 *                                      (e.g. the height of the video)
 *
 * @include fixed-ratio(320px, 240px);
 */
@function _fixed-ratio_strip-units($number) {
	@return $number / ($number * 0 + 1);
}
@mixin fixed-ratio ($pixel-width: false, $pixel-height: false, $position: top) {
	position: relative;
	display: block;
	&::before {
		content: "";
		display: block;
		@if $pixel-width and $pixel-height {
			$ratio: $pixel-width / $pixel-height;
			padding-#{$position}: _fixed-ratio_strip-units(100% / $ratio) * 1%;
		}
		@content;
	}
}
@mixin fixed-ratio--no-pseudo ($pixel-width, $pixel-height, $position: top) {
	$ratio: $pixel-width / $pixel-height;
	position: relative;
	display: block;
	padding-#{$position}: _fixed-ratio_strip-units(100% / $ratio) * 1%;
}
@mixin fixed-ratio_fill () {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
}
.FixedRatio--square {
	overflow: hidden;
	@include fixed-ratio(1, 1);
	& > * {
		@include fixed-ratio_fill();
	}
	& > img,
	& > video {
		object-fit: cover;
	}
}
.FixedRatio--16-9 {
	overflow: hidden;
	@include fixed-ratio(16, 9);
	& > * {
		@include fixed-ratio_fill();
	}
	& > img,
	& > video {
		object-fit: cover;
	}
}
.FixedRatio--16-9--no-pseudo {
	overflow: hidden;
	@include fixed-ratio--no-pseudo(16, 9);
	& > * {
		@include fixed-ratio_fill();
	}
	& > img,
	& > video {
		object-fit: cover;
	}
	& > video {
		height: 100.5%;
		width: 100.5%;
	}
}
