$bhp: () !global;

$error-wrong-length: "breakpoints() error; $breakpoints are invalid, they have to be of odd length and at least 3";
$error-wrong-format: "breakpoints() error; $breakpoints are invalid. Alternate range names with breakpoints in px or em. Start with a range name.";

/**
 * Add breakpoint definitions
 * @param  {string}   $section-name   Anything, usually a site section name or just "global"
 * @param  {sequence} $breakpoints... A definitions object that alternates column names with breakpoints. Examples below.
 *

 // one breakpoint. single-column below 500px, two-columngs above and including 500px;
 @include breakpoints(home, single-column, 500px, two-columns);

 // two breakpoints. phone below 400px, desktop above and including 800px, tablet between the two
 @include breakpoints(home, phone, 400px, tablet, 800px, desktop);

*/

@mixin breakpoints($section-name, $breakpoints...) {
	$length: length($breakpoints);
	@if $length < 3 or $length % 2 == 0 {
		@error $error-wrong-length;
	}
	$i: 0;
	@each $piece in $breakpoints {
		$is-even: $i % 2 == 0;
		$is-valid-number: (type_of($piece) == number and (unit($piece) == px or unit($piece) == em));
		@if $is-even == $is-valid-number {
			@error $error-wrong-format;
		}
		$i: $i + 1;
	}
	$section: (
		name: $section-name,
		breakpoints: $breakpoints
	);
	$bhp: map-merge($bhp, ($section-name: $section)) !global;
}

@function get-mq($section-name, $range-name, $mq-type: only) {
	$section: map-get($bhp, $section-name);
	@if not $section {
		@error "get-mq() error; #{$section-name} was not found. Add it with breakpoints() first";
	}
	$min: all;
	$max: all;

	$breakpoints: map-get($section, breakpoints);
	$range-pos: index($breakpoints, $range-name);
	@if $range-pos == null {
		@error "get-mq() error; #{$range-name} was not found in #{$section-name}";
	}

	$is-first-range: $range-pos == 1;
	$is-last-range: $range-pos == length($breakpoints);
	// @debug $range-pos, $is-first-range, $is-last-range;

	// first range doesn't have a "and min"; it means "all"
	@if not $is-first-range {
		$min: '(min-width: ' nth($breakpoints, $range-pos - 1) ')';
	}

	// last range doesn't have a "and below"; it means "all"
	@if not $is-last-range {
		$max: '(max-width: ' nth($breakpoints, $range-pos + 1) * 0.99999 ')';
	}

	// @debug $min, $max;

	// last range is always min-width
	@if $mq-type == min or $is-last-range {
		@return $min;
	}

	// first range is always max-width
	@if $mq-type == max or $is-first-range {
		@return $max;
	}

	// first range is always max-width
	@if $mq-type == only {
		@return $min #{"and"} $max;
	}

	@error "get-mq() error; #{$mq-type} is a wrong $mq-type. Use min, max, or only";
}

@mixin media($section-name, $range-name, $mq-type: only) {
	@media #{get-mq($section-name, $range-name, $mq-type)} {
		@content;
	}
}
