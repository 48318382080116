@include breakpoints(head, top, 700px, side);

.Head {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 999999999;
}
.Head-logo {
  @extend .ir;
  margin: ($spacing*0.7) $spacing ($spacing*0.6) auto;
  $width: 122px;
  $height: 52px;
  width: $width;
  height: $height;
  background: url(../assets/images/logo.png) no-repeat;
  @media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi) {
    background-image: url(../assets/images/logo-2x.png);
    background-size: $width $height;
  }
  transition: opacity .3s;
  &:hover,
  &:focus {
    transition: none;
    opacity: 0.9;
  }
}

.SiteNav {
  @extend %list-reset;
  text-align: right;
  font-size: 25px;
  line-height: 1.1;
}

%SiteNav-indicator {
  content: "";
  position: absolute;
  z-index: -1;
  right: 14px;
  top: 50%;
  display: block;
  margin-top: -3px;
  width: 7px;
  border-top: 7px solid;
  transform: rotate(45deg); // transform: translateZ(0px) rotate(45deg); /*fixes layer change during transition, but screws up subpixel smoothin*/
  transition: .3s;
  opacity: 0;
}

.SiteNav-link {
  @extend .Link--styled;
  width: 100%;
  display: block;
  padding: 0 $spacing;
  position: relative;
  z-index: 0;
  margin-top: 10px;
  &:after {
    @extend %SiteNav-indicator;
    transform: translate(px-to-rem(-14px)) rotate(180deg);
  }
  &:before {
    @extend %SiteNav-indicator;
    transition: none;
    /*disabled: transitions makes it look busy*/
  }
  &:hover,
  &:focus {
    &:before {
      transition: none;
      opacity: 0.5;
    }
  }
}

.SiteNav-link--current {
  font-weight: bold;
  &:after {
    transform: rotate(45deg);
    opacity: 1;
  }
}

.SiteNav-link--plus--OFF {
  line-height: 1.4;
  margin-bottom: 3px; // background: #fff;
  background: $color-blue;
  color: $color-beige;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: background .3s;
  &:hover,
  &:focus {
    // background: mix($color-blue, #fff, 10%);
    transition: none;
    background: mix($color-blue, $color-beige, 90%);
  }
  &:before {
    content: none;
    /*remove hover indicator*/
  }
}
.SiteNav-toggle {
  @extend %button-reset;
  @extend .SiteNav-link;
  font-size: 16px;
  line-height: 1.2;
  margin-top: 0;
  .is-open & {
    display: none !important;
  }
  &:before {
    content: none;
  }
}

@include media(head, top) {
  .Head {
    display: flex;
    align-items: center;
    background-color: $color-beige;
    box-shadow: rgba($color-blue, 0.2) 0px 2px 19px;
    padding: 10px;
  }
  .SiteNav {
    margin-left: auto;
  }
  .Head-logo {
    margin: 0;
    margin-right: 10px;
  }
  .SiteNav-link {
    display: none;
    margin-top: 0;
    padding: 0;
    &:before,
    &:after {
      display: none;
    }
  }
  .is-open .SiteNav-link,
  .SiteNav-link--current,
  .SiteNav-toggle {
    display: block;
  }
  .is-open {
    flex-wrap: wrap;
    .Head-logo {
      width: 100%;
    }

    .SiteNav-link {
      margin-top: 20px;
    }
  }
  .is-open + .Head-overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 999999998;
    background-color: rgba($color-blue, 0.8);
  }
  .Head:not(.is-open) .SiteNav-toggle {
    position: static;
    &:before {
      all: unset;
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }
  }
}
@include media(head, side) {
  body {
    display: flex;
    align-items: flex-start;
  }
  .Head {
    white-space: nowrap;
  }
  .SiteNav-link--minus {
    font-size: 16px;
    margin-top: 0;
  }
  .SiteNav-toggle {
    display: none;
  }
}
