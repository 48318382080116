/* Some global page stuff */

@-ms-viewport { min-width: device-width; }
@-o-viewport { min-width: device-width; }
@viewport { min-width: device-width; }


html {
  overflow-y: scroll;
  background: $color-beige;
  color: $color-blue;
	scroll-behavior: smooth;
	word-break: break-word;
}

body {
  min-width: $min-site-width;
  max-width: $max-site-width;
  position: relative;
  padding: 0;
  margin: auto;
}

a {
	color: inherit;
}

*, *:before, *:after {
	box-sizing: border-box;
}

img, svg, video {
	box-sizing: content-box;
}

h1, h2, h3, h4, h5, h6, p, ul, ol, blockquote {
	margin-top: 0;
	margin-bottom: $line-margin;
	&:last-child {
		margin-bottom: 0;
	}
}
h1, h2, h3, h4, h5, h6 {
	font-weight: normal;
	font-size: 1em;
}

img {
	max-width: 100%;
	height: auto;
}
iframe {
	max-width: 100%;
	border: none;
	overflow: hidden;
}

[hidden] {
	display: none !important;
}

%ir {
	text-indent: 110%;
	white-space: nowrap;
	overflow: hidden;
	display: block;
}

@mixin list-reset {
	list-style: none;
	margin-top: 0;
	margin-bottom: 0;
	padding-left: 0;
}
%list-reset {
	@include list-reset;
}
@mixin cf {
	&:after{
		content:"";
		display:table;
		clear:both;
	}
}
%cf{
	@include cf;
}

::selection {
	background: rgba($color-blue, 0.2);
	text-shadow: none;
}
