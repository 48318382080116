@include breakpoints(image-floats, disabled, 600px, enabled);

.u-text-wrap-50 {
	max-width: $max-site-width / 2;
}

.u-2-text-columns {
	column-width: $max-site-width / 2.9;
	column-gap: 80px;
	column-fill: balance;
}
.u-3-text-columns {
	column-width: $max-site-width / 3.9;
	column-gap: 80px;
	column-fill: balance;
}
.u-4-text-columns {
	column-width: $max-site-width / 4.9;
	column-gap: 80px;
	column-fill: balance;
}

.u-avoid-break-inside {
	display: inline-block;
}
.u-text-align-center {
	text-align: center;
}

.u-extend-to-window-borders {
	margin-left: calc(-50vw + 50%);
	margin-right: calc(-50vw + 50%);
}

@include media(image-floats, enabled) {
	.alignleft,
	.alignright {
		max-width: 60%;
		margin-bottom: 1em;
	}
	.alignleft {
		float: left;
		margin-right: 1em;
	}
	.alignright {
		float: right;
		margin-left: 1em;
	}
}
@include media(image-floats, disabled) {
	.alignleft,
	.alignright {
		margin-left: auto !important;
		margin-right: auto !important;
		display: block;
		margin-bottom: $line-margin !important;
	}
}

.aligncenter {
	margin: 0 auto;
	display: block;
}

.screen-reader-text,
.visuallyhidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}


// DELETE!
.ir {
	text-indent: 150%;
	white-space: nowrap;
	overflow: hidden;
	display: block;
}
